const copy = {
  bind(el, {
    value
  }) {
    el.$value = value;

    el.handler = () => {
      if (!el.$value) {
        //    值为空时
        return;
      }

      const textarea = document.createElement('textarea');
      textarea.readOnly = 'readonly';
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      textarea.value = el.$value;
      document.body.appendChild(textarea);
      textarea.select();
      const result = document.execCommand('copy');

      if (result) {
        //  提示复制成功
        console.log('复制成功');
      }

      document.body.removeChild(textarea);
    };
  },

  // 当传进来的值更新的时候触发
  componentUpdated(el, {
    value
  }) {
    el.$value = value;
  },

  // 指令与元素解绑的时候，移除事件绑定
  unbind(el) {
    el.removeEventListener('click', el.handler);
  }

};
export default copy;